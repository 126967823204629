import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import mime from 'mime';
import { environment } from '../../../../environments/environment';
import { ViewState } from '../../../models/app';
import { EditFileDto, FileDto } from '../../../models/dtos';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  // styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public fileId: string = '0';
  file: FileDto = new FileDto();


  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;

  async ngOnInit() {
    //update title
    this.titleService.setTitle('File | Trucking Standards Admin');

    //parse query params
    this.fileId = this.route.snapshot.paramMap.get('fileId') ?? '0';

    this.getFile();
  }

  //api

  getFile(): void {
    this.viewState = ViewState.loading;

    this.http
      .get<FileDto>(`${environment.services_legacy_management}/v1/files/${this.fileId}`)
      .subscribe(result => {
        this.file = result;
        this.viewState = ViewState.content;
      });
  }

  editFile(): void {
    this.viewState = ViewState.loading;

    this.http
      .put<FileDto>(`${environment.services_legacy_management}/v1/files/${this.fileId}`, EditFileDto.fromFile(this.file))
      .subscribe(result => {
        this.file = result;
        this.viewState = ViewState.content;
      });
  }

  deleteFile(): void {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_legacy_management}/v1/files/${this.fileId}`)
      .subscribe(() => {
        this._router.navigateByUrl('/files')
      });
  }


  //validations
  fileIsValid(): Boolean {
    return this.isValidString(this.file.name)
      && this.isValidString(this.file.url);
  }

  isValidString(test?: string): Boolean {
    if (test == null) { return false; }
    return test.trim() != '';
  }

  formattedDocSize(size?: number): string {
    if(size) {
      if (size > 1000000) {
        return `${(size / 1000000).toFixed(2)} MB`
      }
      else if (size > 1000) {
        return `${(size / 1000).toFixed(2)} KB`
      }
      else {
        return `${size.toFixed(2)} bytes`
      }
    }
    return "Unkown";
  }

  fileIconType(): string | null {
    console.log(mime.getExtension(this.file.mimeType));
    return mime.getExtension(this.file.mimeType)
  }
}
