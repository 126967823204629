<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">

     <!--loading-->
     <div *ngIf="viewState == viewStates.loading" class="text-center">
      <div class="spinner-border text-ts" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="card p-4" *ngIf="viewState == viewStates.content">
      <div>

        <div class="text-small text-center mb-4">
          <b>File Info</b>
        </div>

        <!--file-->
        <div class="text-center">
          <a [href]="file.url" target="_blank">
            <span class="fiv-viv fiv-icon-{{fileIconType()}} fiv-size-xl"></span>
          </a>
          
          <div class="mt-2 text-sm">{{file.name}} ({{formattedDocSize(file.size)}})</div>
          <div class="text-center text-small text-secondary mt-2">Click icon to open file</div>
        </div>

        <div>
          <div class="flex-auto">
            <!--name-->
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" placeholder="Search files..." class="form-control"
                [(ngModel)]="file.name">
            </div>
            <!-- <mat-form-field class="w-full">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="file.name" [autocomplete]="'off'" placeholder="File Name" [disabled]="!permissionsService.canManage()">
              <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </mat-form-field> -->
          </div>

        </div>
      </div>

      <button class="mt-4 w-full btn btn-pill btn-ts" mat-flat-button (click)="editFile()"
        [disabled]="!fileIsValid()">
        <!-- <mat-icon [svgIcon]="'heroicons_outline:save'"></mat-icon> -->
        <span class="ms-2 mr-1">Save</span>
      </button>

      <!--delete-->
      <div class="border rounded p-3 mt-4 border-rse-500o">
        <div class="text-warn"><b>Danger Zone</b></div>
        <div><small>These actions are destructive. Proceed with care.</small></div>
        <button class="mt-2 w-100 btn btn-pill btn-danger" mat-stroked-button mat-button-base
          [swal]="{ title: 'Delete File', confirmButtonText: 'Delete', icon: 'error', confirmButtonColor: '#EF4444', showCancelButton: true }"
          (confirm)="deleteFile()">

          Delete
        </button>
      </div>
    </div>

  </div>

</div>