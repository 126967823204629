<div class="flex flex-col flex-auto min-w-0">

  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!--loading-->
    <div *ngIf="viewState == viewStates.loading" class="text-center">
      <div class="spinner-border text-ts" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="card p-4" *ngIf="viewState == viewStates.content">
      <div>

        <div class="text-small text-center mb-4">
          <!-- <mat-icon class="mr-2" style="vertical-align: bottom;" matSuffix [svgIcon]="'heroicons_solid:pencil-alt'">
          </mat-icon> -->
          <b>File To Add Info</b>
        </div>

        <!--file-->
        <div>
          <!--loading-->
          <div *ngIf="fileViewState == viewStates.loading" class="text-center">
            <div class="spinner-border text-ts" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <!--file pre-or-post upload-->
          <div *ngIf="fileViewState == viewStates.content">
            <div class="form-group">
              <input type="file" style="display: none;" id="resource-image" accept="application/*"
                (change)="editFile($event)" #image>
            </div>
            <div *ngIf="fileToAdd.url == null" class="text-center">
              <img class="object-contain"
                src="https://img.icons8.com/color/256/000000/new-by-copy.png"
                style="width: 128px; height: 128px; cursor: pointer;"
                (click)="imageElement?.nativeElement.click()" />
              <!-- <img *ngIf="fileToAdd?.imageUrl != null"
                class="object-cover w-32 h-32 ml-auto mr-auto border rounded cursor-pointer" src="{{fileToAdd.imageUrl}}"
                alt="Card cover image" (click)="imageElement.nativeElement.click()"> -->
              <div class="text-center text-small mt-2">Click image to upload file</div>
            </div>
            <div *ngIf="fileToAdd.url != null" class="text-center">
              <span class="fiv-viv fiv-icon-{{fileIconType()}} fiv-size-xl"></span>
              <div class="mt-2 text-sm">{{fileToAdd.name}} ({{formattedDocSize(fileToAdd.size)}})</div>
            </div>
          </div>
        </div>

        <div>
          <div class="flex-auto">

            <!--name-->
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="file-name" placeholder="File name" class="form-control"
                [(ngModel)]="fileToAdd.name">
            </div>
          </div>

        </div>
      </div>

      <button class="mt-4 w-full btn btn-pill btn-ts" (click)="addFile()"
        [disabled]="!fileIsValid()">
        <!-- <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon> -->
        <span class="ms-2 mr-1">Add</span>
      </button>
    </div>
  </div>
</div>