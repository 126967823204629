<!-- <div *ngIf="!picking" class="row">
  <div class="col-6">
    <h1>Files</h1>
  </div>
  <div class="col-6 text-right">
    <a class="btn btn-pill btn-ts" routerLink="/add-file">
      <span class="mb-2 me-1">+ Add File </span>
    </a>
  </div>
</div> -->

  <!-- Search -->
<!-- <div class="flex items-center mt-4 sm:mt-0 md:mt-4">

  <div class="flex-auto">
    <div class="form-group">
      <input type="text" id="section-title" placeholder="Search files..." class="form-control" [(ngModel)]="searchText"
        (ngModelChange)="seachTextDidChange(searchText)">
    </div>
  </div>
</div> -->


<!--list-->
<!--loading-->
<div *ngIf="viewState == viewStates.loading" class="text-center">
  <div class="spinner-border text-ts" role="status">
    <!-- <span class="sr-only">Loading...</span> -->
  </div>
</div>

<!-- no content -->
<!-- <div *ngIf="viewState == viewStates.content && filteredFiles.length === 0" class="mt-8">
  <app-generic-no-content 
  title="No Files Found"
  subtitle="No files were found please refresh or update your search criteria."
  imageUrl="https://img.icons8.com/color/256/000000/files.png"
  class="ml-auto mr-auto mt-5"></app-generic-no-content>
</div> -->

<!-- content -->
<c-card *ngIf="viewState == viewStates.content" class="mb-4 ">
  <c-card-body>
    <c-row *ngIf="!picking">
      <c-col sm="7">
        <h3 class="card-title mb-0" id="traffic">
          <img src="https://img.icons8.com/color/200/000000/books.png" class="rounded me-2"
            style="object-fit: contain; width: 30px; height: 30px;">
          <b>Files</b>
        </h3>
      </c-col>
      <c-col sm="5">
        <div style="text-align: right;">
          <a class="btn btn-pill btn-ts" routerLink="/add-file">
            <button class="btn btn-pill btn-dark btn-ts">+ Add File</button>
          </a>
        </div>
      </c-col>
    </c-row>

    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
      <!-- Search -->
      <div class="flex-auto">
        <div class="form-group">
          <input type="text" id="section-title" placeholder="Search files..." class="form-control" [(ngModel)]="searchText"
            (ngModelChange)="seachTextDidChange(searchText)">
        </div>
      </div>
    </div>

    <div class="w-full overflow-x-auto mx-6 mt-4">
      <table class="table">
        <thead>
          <th>Name</th>
          <th>Created</th>
          <th>Size</th>
          <th>Copy Link</th>
        </thead>
        <tbody>
          <tr *ngFor="let file of filteredFiles">
            <td>
              <a [href]="file.url" target="_blank" class="me-3 bold">
                <i class="fa fa-external-link"></i>
              </a>
              <a *ngIf="!picking" routerLink="/files/{{file.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                <span class="fiv-viv fiv-icon-{{fileIconType(file.mimeType)}} fiv-size-sm me-2"></span>
                <b class="text-ts">{{file.name}}</b>
              </a>
              <a *ngIf="picking" target="_blank" routerLink="/files/{{file.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                <span class="fiv-viv fiv-icon-{{fileIconType(file.mimeType)}} fiv-size-sm me-2"></span>
                <b class="text-ts">{{file.name}}</b>
              </a>
            </td>
            <td>
              <span class="whitespace-nowrap">
                {{moment(file.timestampCreated).format('lll')}}
              </span>
            </td>

            <td>
              <span class="whitespace-nowrap">
                {{formattedDocSize(file.size)}}
              </span>
            </td>
            <td>
              <button class="btn btn-default" type="button" (click)="copyMessage(file)">
                <i *ngIf="copiedFile?.id != file.id" class="fa fa-copy"></i>
                <span class="ms-2 text-success" *ngIf="copiedFile?.id == file.id">Copied!</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </c-card-body>
</c-card>



<!-- <div *ngIf="viewState == viewStates.content && filteredFiles.length > 0" class="w-full mt-4">
  <div class="card w-full p-4">
    <div class="w-full overflow-x-auto mx-6">
      <table class="table">
        <thead>
          <th>Name</th>
          <th>Created</th>
          <th>Size</th>
          <th>Copy Link</th>
        </thead>
        <tbody>
          <tr *ngFor="let file of filteredFiles">
            <td>
              <a [href]="file.url" target="_blank" class="me-3 bold">
                <i class="fa fa-external-link"></i>
              </a>
              <a *ngIf="!picking" routerLink="/files/{{file.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                <span class="fiv-viv fiv-icon-{{fileIconType(file.mimeType)}} fiv-size-sm me-2"></span>
                <b class="text-ts">{{file.name}}</b>
              </a>
              <a *ngIf="picking" target="_blank" routerLink="/files/{{file.id}}" class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                <span class="fiv-viv fiv-icon-{{fileIconType(file.mimeType)}} fiv-size-sm me-2"></span>
                <b class="text-ts">{{file.name}}</b>
              </a>
            </td>
            <td>
              <span class="whitespace-nowrap">
                {{moment(file.timestampCreated).format('lll')}}
              </span>
            </td>

            <td>
              <span class="whitespace-nowrap">
                {{formattedDocSize(file.size)}}
              </span>
            </td>
            <td>
              <button class="btn btn-default" type="button" (click)="copyMessage(file)">
                <i *ngIf="copiedFile?.id != file.id" class="fa fa-copy"></i>
                <span class="ms-2 text-success" *ngIf="copiedFile?.id == file.id">Copied!</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div> -->