import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import mime from 'mime';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewState } from '../../../models/app';
import { AddFileDto, FileDto, FileResponseDto } from '../../../models/dtos';

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  // styleUrls: ['./add-file.component.css']
})
export class AddFileComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  fileToAdd: AddFileDto = new AddFileDto();
  file: File | null | undefined;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;
  companyViewState = ViewState.loading;
  fileViewState = ViewState.content;

  //file upload
  @ViewChild('image') imageElement?: ElementRef;

  async ngOnInit() {
    //update title
    this.titleService.setTitle(`Add File | Trucking Standards Admin`);
  }

  //api
  addFile(): void {
    this.viewState = ViewState.loading;

    this.http
      .post<FileDto>(`${environment.services_legacy_management}/v1/files`, this.fileToAdd)
      .subscribe(result => {
        this._router.navigateByUrl(`/files/${result.id}`);
      });
  }

  async uploadFile(file: File | null | undefined): Promise<FileResponseDto> {
    //snapshot file name for presentation
    this.file = file;

    //package form data
    const formData: FormData = new FormData();
    if(file != null) {
      formData.append('file', file, file.name);
    }

    //upload
    return (await firstValueFrom(this.http.post(`${environment.services_legacy_management}/v1/files-upload`, formData))) as FileResponseDto;
  }

  async editFile(e: Event) {
    //get file
    const file: File | null | undefined = (<HTMLInputElement>e.target).files?.item(0);

    //upload
    this.fileViewState = ViewState.loading;
    const uploadedFile = await this.uploadFile(file);
    this.fileToAdd.url = uploadedFile.src;
    this.fileToAdd.size = this.file?.size;
    this.fileToAdd.mimeType = this.file?.type;
    this.fileViewState = ViewState.content;
  }

  //validations
  fileIsValid(): Boolean {
    return this.isValidString(this.fileToAdd.name)
      && this.isValidString(this.fileToAdd.url);
  }

  isValidString(test?: string): Boolean {
    if (test == null) { return false; }
    return test.trim() != '';
  }

  formattedDocSize(size?: number): string {
    if(size) {
      if (size > 1000000) {
        return `${(size / 1000000).toFixed(2)} MB`
      }
      else if (size > 1000) {
        return `${(size / 1000).toFixed(2)} KB`
      }
      else {
        return `${size.toFixed(2)} bytes`
      }
    }
    return "Unkown";
  }

  fileIconType(): string | null {
    // console.log(mime.getExtension(this.fileToAdd?.mimeType ?? ''));
    return mime.getExtension(this.fileToAdd?.mimeType ?? '');
  }
}
