import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import mime from 'mime';
import { FileDto } from '../../../models/dtos';
import { ViewState } from '../../../models/app';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  // styleUrls: ['./files-list.component.css']
})
export class FilesListComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  //inputs
  @Input() filesRoute?: string;
  @Input() companyId?: string;
  @Input() picking: boolean = false;


  //vars
  files: FileDto[] = []
  filteredFiles: FileDto[] = []
  searchText: string = '';
  moment = moment;

  copiedFile?: FileDto | null;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addFileViewState = ViewState.content;

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.getFiles();
  }

  //api
  getFiles(): void {
    this.viewState = ViewState.loading;

    this.http
      .get<FileDto[]>(`${environment.services_legacy_management}${this.filesRoute}`)
      .subscribe(result => {
        this.files = result;
        this.filteredFiles = this.files;
        this.viewState = ViewState.content;
      });
  }

  seachTextDidChange(text: string) {
    this.filteredFiles = this.files.filter(r =>
      r.name.toLowerCase().includes(text.toLowerCase())
      || r.mimeType.toLowerCase().includes(text.toLowerCase())
    )
  }


  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  fileIconType(mimeType: string): string | null {
    return mime.getExtension(mimeType);
  }

  formattedDocSize(size: number): string {
    if (size > 1000000) {
      return `${(size / 1000000).toFixed(2)} MB`
    }
    else if (size > 1000) {
      return `${(size / 1000).toFixed(2)} KB`
    }
    else {
      return `${size.toFixed(2)} bytes`
    }
  }

  copyMessage(file: FileDto){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = file.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.copiedFile = file;
    setTimeout(() => {
      this.copiedFile = null;
    }, 1000);
  }
}
